import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/layout/layout";

// markup
const IndexPage = () => {
  return (
    <Layout title="Lovely Ladyverse - The Trixieverse">
      <h1 class="flex-pageheading">The Trixieverse</h1>
      <StaticImage src="../images/thetrixieverse.png" alt="The Trixieverse"/>
      <p class= "flex-textbox">The 'Trixieverse' is a modern setting within The Lovely Ladyverse, following the exploits of Trixie Glimmer Smith and her partners Nikita and Tabby.</p>
    </Layout>
  );
};

export default IndexPage;
